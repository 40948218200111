<!-- ExperimentComponent.vue -->
<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import * as THREE from 'three';
import { gsap } from 'gsap';

const canvasRef = ref(null);
const angleOfIncidence = ref(45);
const selectedMedium = ref('water');
const AIR_REFRACTIVE_INDEX = 1.0;

const mediums = {
  water: { name: 'Water', refractiveIndex: 1.33, color: 0x0077BE },
  glass: { name: 'Glass', refractiveIndex: 1.52, color: 0xC5E5E5 },
  oil: { name: 'Oil', refractiveIndex: 1.47, color: 0xFFD700 },
  diamond: { name: 'Diamond', refractiveIndex: 2.42, color: 0xE6E6FA },
  alcohol: { name: 'Alcohol', refractiveIndex: 1.36, color: 0xF0FFF0 }
};

const currentMedium = computed(() => mediums[selectedMedium.value]);

let scene, camera, renderer, airMesh, mediumMesh, lightRay, refractedRay, normalLine;
let incidentAngleArc, refractedAngleArc, incidentAngleLabel, refractedAngleLabel;
let animationFrameId;

const init = () => {
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0xf0f0f0);

  camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
  camera.position.set(0, 0, 10);
  camera.lookAt(0, 0, 0);

  renderer = new THREE.WebGLRenderer({ canvas: canvasRef.value, antialias: true });
  updateRendererSize();

  // Create air medium
  const airGeometry = new THREE.PlaneGeometry(10, 5);
  const airMaterial = new THREE.MeshBasicMaterial({ color: 0xADD8E6, transparent: true, opacity: 0.3 });
  airMesh = new THREE.Mesh(airGeometry, airMaterial);
  airMesh.position.y = 2.5;
  scene.add(airMesh);

  // Create medium mesh (initially water)
  const mediumGeometry = new THREE.PlaneGeometry(10, 5);
  const mediumMaterial = new THREE.MeshBasicMaterial({ color: currentMedium.value.color, transparent: true, opacity: 0.5 });
  mediumMesh = new THREE.Mesh(mediumGeometry, mediumMaterial);
  mediumMesh.position.y = -2.5;
  scene.add(mediumMesh);

  // Create light ray
  lightRay = new THREE.Line(
    new THREE.BufferGeometry(),
    new THREE.LineBasicMaterial({ color: 0xFFFF00, linewidth: 2 })
  );
  scene.add(lightRay);

  // Create refracted ray
  refractedRay = new THREE.Line(
    new THREE.BufferGeometry(),
    new THREE.LineBasicMaterial({ color: 0xFF0000, linewidth: 2 })
  );
  scene.add(refractedRay);

  // Create normal line (dotted vertical line)
  const normalMaterial = new THREE.LineDashedMaterial({
    color: 0x000000,
    linewidth: 1,
    scale: 1,
    dashSize: 0.2,
    gapSize: 0.1,
  });
  normalLine = new THREE.Line(new THREE.BufferGeometry(), normalMaterial);
  scene.add(normalLine);

  // Create angle arcs
  incidentAngleArc = createAngleArc(0x00FF00);
  refractedAngleArc = createAngleArc(0x0000FF);
  scene.add(incidentAngleArc);
  scene.add(refractedAngleArc);

  // Create angle labels
  incidentAngleLabel = createTextLabel('');
  refractedAngleLabel = createTextLabel('');
  scene.add(incidentAngleLabel);
  scene.add(refractedAngleLabel);

  // Add ambient light
  const ambientLight = new THREE.AmbientLight(0x404040);
  scene.add(ambientLight);

  window.addEventListener('resize', updateRendererSize);

  updateRays();
  animate();
};

const createAngleArc = (color) => {
  const arc = new THREE.Line(
    new THREE.BufferGeometry(),
    new THREE.LineBasicMaterial({ color: color, linewidth: 2 })
  );
  return arc;
};

const createTextLabel = (text) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = 128;
  canvas.height = 64;
  context.font = 'Bold 24px Arial';
  context.fillStyle = 'black';
  context.textAlign = 'center';
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  const texture = new THREE.CanvasTexture(canvas);
  const material = new THREE.SpriteMaterial({ map: texture });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(1, 0.5, 1);
  return sprite;
};

const updateAngleArc = (arc, center, radius, startAngle, endAngle) => {
  const points = [];
  for (let i = startAngle; i <= endAngle; i += 0.1) {
    points.push(new THREE.Vector3(
      center.x + radius * Math.cos(i),
      center.y + radius * Math.sin(i),
      0
    ));
  }
  arc.geometry.setFromPoints(points);
};

const updateTextLabel = (label, text, position) => {
  const canvas = label.material.map.image;
  const context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.font = 'Bold 24px Arial';
  context.fillStyle = 'black';
  context.textAlign = 'center';
  context.fillText(text, canvas.width / 2, canvas.height / 2);
  label.material.map.needsUpdate = true;
  label.position.copy(position);
};

const updateRendererSize = () => {
  const container = canvasRef.value.parentElement;
  const width = container.clientWidth;
  const height = container.clientHeight;
  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
};

const updateRays = () => {
  const incidentAngle = THREE.MathUtils.degToRad(90 - angleOfIncidence.value);
  const n1 = AIR_REFRACTIVE_INDEX;
  const n2 = currentMedium.value.refractiveIndex;

  // Calculate refracted angle using Snell's law
  const refractedAngle = Math.asin((n1 / n2) * Math.sin(incidentAngle));

  // Update light ray
  const lightStart = new THREE.Vector3(-5, 5, 0);
  const lightEnd = new THREE.Vector3(
    lightStart.x + 5 * Math.sin(incidentAngle),
    lightStart.y - 5 * Math.cos(incidentAngle),
    0
  );
  lightRay.geometry.setFromPoints([lightStart, lightEnd]);

  // Extend the light ray to the medium surface
  const mediumSurfaceY = 0;
  const t = (mediumSurfaceY - lightStart.y) / (lightEnd.y - lightStart.y);
  const intersectionPoint = new THREE.Vector3(
    lightStart.x + t * (lightEnd.x - lightStart.x),
    mediumSurfaceY,
    0
  );

  // Update light ray to stop at medium surface
  lightRay.geometry.setFromPoints([lightStart, intersectionPoint]);

  // Update refracted ray
  const refractedEnd = new THREE.Vector3(
    intersectionPoint.x + 5 * Math.sin(refractedAngle),
    intersectionPoint.y - 5 * Math.cos(refractedAngle),
    0
  );
  refractedRay.geometry.setFromPoints([intersectionPoint, refractedEnd]);

  // Update normal line
  normalLine.geometry.setFromPoints([
    new THREE.Vector3(intersectionPoint.x, intersectionPoint.y + 2, 0),
    new THREE.Vector3(intersectionPoint.x, intersectionPoint.y - 2, 0)
  ]);
  normalLine.computeLineDistances();

  // Update medium color
  mediumMesh.material.color.setHex(currentMedium.value.color);

  // Update angle arcs
  const arcRadius = 1;
  updateAngleArc(incidentAngleArc, intersectionPoint, arcRadius, -Math.PI / 2, -incidentAngle);
  updateAngleArc(refractedAngleArc, intersectionPoint, arcRadius, -Math.PI / 2, -refractedAngle);

  // Update angle labels
  const incidentLabelPosition = new THREE.Vector3(
    intersectionPoint.x + arcRadius * 0.7 * Math.cos((Math.PI/2 - incidentAngle) / 2),
    intersectionPoint.y + arcRadius * 0.7 * Math.sin((Math.PI/2 - incidentAngle) / 2),
    0
  );
  updateTextLabel(incidentAngleLabel, `${angleOfIncidence.value.toFixed(1)}°`, incidentLabelPosition);
  
  const refractedLabelPosition = new THREE.Vector3(
    intersectionPoint.x + arcRadius * 0.7 * Math.cos((Math.PI/2 - refractedAngle) / 2),
    intersectionPoint.y - arcRadius * 0.7 * Math.sin((Math.PI/2 - refractedAngle) / 2),
    0
  );
  const refractedAngleDegrees = THREE.MathUtils.radToDeg(Math.PI/2 - refractedAngle);
  updateTextLabel(refractedAngleLabel, `${refractedAngleDegrees.toFixed(1)}°`, refractedLabelPosition);
};

const animate = () => {
  animationFrameId = requestAnimationFrame(animate);
  renderer.render(scene, camera);
};

onMounted(() => {
  init();
  gsap.fromTo(scene.position, 
    { y: -10 }, 
    { duration: 1, y: 0, ease: 'power2.out' }
  );
});

onUnmounted(() => {
  cancelAnimationFrame(animationFrameId);
  window.removeEventListener('resize', updateRendererSize);
  renderer.dispose();
});

watch([angleOfIncidence, selectedMedium], updateRays);
</script>

<template>
  <div class="experiment-container">
    <div class="canvas-container">
      <canvas ref="canvasRef"></canvas>
    </div>
    <div class="controls">
      <h2>Refractive Index Simulation</h2>
      
      <div class="control-group">
        <label for="angleOfIncidence">Angle of Incidence (degrees):</label>
        <input 
          id="angleOfIncidence"
          type="range" 
          v-model="angleOfIncidence" 
          min="0" 
          max="89" 
          step="1"
        >
        <span>{{ angleOfIncidence }}°</span>
      </div>
      
      <div class="control-group">
        <label>Select Medium:</label>
        <div class="radio-group">
          <div v-for="(medium, key) in mediums" :key="key" class="radio-option">
            <input 
              type="radio" 
              :id="key" 
              :value="key" 
              v-model="selectedMedium"
              name="mediumRadioGroup"
            >
            <label :for="key">
              {{ medium.name }} (n = {{ medium.refractiveIndex }})
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.experiment-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.canvas-container {
  flex: 1;
  min-width: 0;
}

canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.controls {
  width: 300px;
  padding: 20px;
  background-color: #f0f0f0;
  overflow-y: auto;
}

.control-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="range"] {
  width: 100%;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  margin-bottom: 5px;
}

.radio-option input[type="radio"] {
  margin-right: 5px;
}
</style>