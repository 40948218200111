<template>
  <div class="container">
    <!-- Prompt to select an image -->
    <p>Please select an image from the grid below:</p>

    <!-- Grid of images -->
    <div class="image-grid">
      <img 
        v-for="image in imageOptions" 
        :key="image" 
        :src="require(`@/assets/${image}`)" 
        :alt="image"
        class="grid-image" 
        @click="handleImageSelection(image)"
      />
    </div>

    <!-- Display the selected image -->
    <div v-if="fileUrl" class="image-preview">
      <img v-if="isImage" :src="fileUrl" ref="mediaElement" class="selected-image"/>
    </div>

    <!-- Button to detect objects -->
    <div class="action-container">
      <button 
        v-if="!detectionComplete" 
        @click="detectObjects" 
        :disabled="!fileUrl || !isDetectorReady" 
        class="detect-button"
      >
        Detect People
      </button>
      <canvas ref="canvas" v-show="showCanvas" class="result-canvas"></canvas>
    </div>

    <p v-if="error" class="error">{{ error }}</p>
    <p v-if="!isDetectorReady" class="loading-message">Loading object detector...</p>
  </div>
</template>

<script>
import { ObjectDetector, FilesetResolver } from '@mediapipe/tasks-vision';

export default {
  data() {
    return {
      selectedImage: '', // The selected image from grid
      fileUrl: null,
      isImage: false,
      showCanvas: false,
      error: null,
      objectDetector: null,
      isDetectorReady: false,
      detectionComplete: false, // New flag for button visibility
      imageOptions: ['1.png','2.png','3.png','4.png','5.png','6.png','7.png','8.png','9.jpg','10.jpg','11.jpg'], // List of images
    };
  },
  async mounted() {
    await this.initializeObjectDetection();
  },
  methods: {
    async initializeObjectDetection() {
      try {
        const vision = await FilesetResolver.forVisionTasks(
          "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
        );
        this.objectDetector = await ObjectDetector.createFromOptions(vision, {
          baseOptions: {
            modelAssetPath: "https://storage.googleapis.com/mediapipe-models/object_detector/efficientdet_lite0/float16/1/efficientdet_lite0.tflite",
            delegate: "GPU",
          },
          scoreThreshold: 0.2,
          maxResults: 10,
        });
        this.isDetectorReady = true;
      } catch (error) {
        console.error("Error initializing object detector:", error);
        this.error = "Failed to initialize object detector. Please try again.";
      }
    },
    handleImageSelection(image) {
      // Reset the detectionComplete flag and set the fileUrl to the selected image path
      this.detectionComplete = false;
      this.fileUrl = require(`@/assets/${image}`);
      this.isImage = true; // As all the selected files are images
      this.showCanvas = false;
      this.error = null;
    },
    async detectObjects() {
      if (!this.fileUrl) {
        this.error = 'Please select an image first.';
        return;
      }

      const mediaElement = this.$refs.mediaElement;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');

      canvas.width = mediaElement.width;
      canvas.height = mediaElement.height;

      ctx.drawImage(mediaElement, 0, 0, canvas.width, canvas.height);

      const detectionResult = await this.objectDetector.detect(canvas);
      this.drawDetections(detectionResult, ctx);

      this.showCanvas = true;
      this.detectionComplete = true; // Set the flag to true after detection is done
    },
    drawDetections(detectionResult, ctx) {
      const midpoints = [];

      for (const detection of detectionResult.detections) {
        if (detection.categories[0].categoryName === 'person') {
          const bbox = detection.boundingBox;

          ctx.strokeStyle = '#00FFFF';
          ctx.lineWidth = 4;
          ctx.strokeRect(bbox.originX, bbox.originY, bbox.width, bbox.height);

          ctx.fillStyle = '#00FFFF';
          ctx.font = '18px Arial';
          ctx.fillText('Person', bbox.originX, bbox.originY > 20 ? bbox.originY - 5 : bbox.originY + 20);

          const midX = bbox.originX + bbox.width / 2;
          const midY = bbox.originY + bbox.height / 2;
          midpoints.push({ x: midX, y: midY });

          // Changed midpoint color to blue
          ctx.fillStyle = 'blue';
          ctx.beginPath();
          ctx.arc(midX, midY, 5, 0, 2 * Math.PI);
          ctx.fill();
        }
      }

      if (midpoints.length >= 2) {
        this.drawLinesAndCalculateDistances(midpoints, ctx);
      }
    },
    drawLinesAndCalculateDistances(midpoints, ctx) {
      const pixelToCmScale = 0.0264;
      const thresholdDistance = 5;

      for (let i = 0; i < midpoints.length; i++) {
        for (let j = i + 1; j < midpoints.length; j++) {
          const pointA = midpoints[i];
          const pointB = midpoints[j];

          const distanceInPixels = Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
          const distanceInCm = (distanceInPixels * pixelToCmScale).toFixed(2);

          const isClose = distanceInCm < thresholdDistance;

          if (isClose) {
            // Red line with enhanced visuals
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;  // Make the red line thicker
            ctx.setLineDash([10, 3]); // Add dashed effect for red line
            ctx.shadowBlur = 15; // Add glow effect
            ctx.shadowColor = 'red'; // Glow color
          } else {
            // Green line with thin stroke
            ctx.strokeStyle = 'green';
            ctx.lineWidth = 1; // Thin green line
            ctx.setLineDash([]); // No dash for green line
            ctx.shadowBlur = 0; // No shadow for green lines
          }

          ctx.beginPath();
          ctx.moveTo(pointA.x, pointA.y);
          ctx.lineTo(pointB.x, pointB.y);
          ctx.stroke();
        }
      }
    }
  },
};
</script>



<style scoped>
.container {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 20px auto;
  text-align: center;
}

.prompt {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.grid-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 8px;
  transition: border-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.grid-image:hover {
  border-color: #00FFFF;
  transform: scale(1.05);
}

.image-preview {
  margin-top: 20px;
  margin-bottom: 20px;
}

.selected-image {
  max-width: 80%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.action-container {
  margin-top: 20px;
}

.detect-button {
  background-color: #00b4d8;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.detect-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.detect-button:hover:not(:disabled) {
  background-color: #0096c7;
}

.result-canvas {
  margin-top: 20px;
  border: 2px solid #00b4d8;
  border-radius: 10px;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.loading-message {
  color:  #00b4d8;
  font-style: italic;
  margin-top: 10px;
}
</style>
