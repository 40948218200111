<template>
  <div class="photosynthesis-simulation">
    <h1>Photosynthesis Simulation</h1>
    
    <div class="simulation-container">
      <canvas ref="canvasRef" width="600" height="400"></canvas>
      
      <div class="controls">
        <h2>Control Panel</h2>
        <div class="control-group" v-for="(key) in controlKeys" :key="key">
          <label>{{ key }}:</label>
          <input
            type="range"
            v-model="controlValues[key]"
            min="0"
            max="100"
          />
          <span>{{ controlValues[key] }}%</span>
        </div>
      </div>
    </div>
    
    <div class="results">
      <h2>Results</h2>
      <p>Oxygen Produced: <strong>{{ oxygenProduced.toFixed(2) }}</strong> units</p>
      <p>Glucose Produced: <strong>{{ glucoseProduced.toFixed(2) }}</strong> units</p>
      <p>Plant Height: <strong>{{ plantHeight.toFixed(2) }}</strong> units</p>
    </div>
    
    <div class="explanation">
      <h2>How Photosynthesis Works</h2>
      <p>Photosynthesis is the process by which plants use sunlight, water, and carbon dioxide to produce oxygen and energy in the form of sugar.</p>
      <ol>
        <li><strong>Light Absorption:</strong> Chlorophyll in the plant leaves absorbs light energy.</li>
        <li><strong>Water Breakdown:</strong> The plant uses the light energy to split water molecules into hydrogen and oxygen.</li>
        <li><strong>CO2 Conversion:</strong> The plant takes in carbon dioxide from the air.</li>
        <li><strong>Glucose Production:</strong> Using the hydrogen from water and the carbon dioxide, the plant produces glucose.</li>
        <li><strong>Oxygen Release:</strong> Oxygen is released as a byproduct of this process.</li>
      </ol>
      <p>Adjust the sliders to see how changing light intensity, CO2 levels, and water availability affects the rate of photosynthesis and tree growth!</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

const controlValues = ref({
  "Light Intensity": 50,
  "CO2 Level": 50,
  "Water Level": 50,
});

const oxygenProduced = ref(0);
const glucoseProduced = ref(0);
const plantHeight = ref(5); // Start with a very small tree

const canvasRef = ref(null);
let animationId = null;
const maxPlantHeight = 200; // Restrict the plant height to half the canvas height (400/2)

const controlKeys = Object.keys(controlValues.value);

const updateSimulation = () => {
  const photosynthesisRate = (controlValues.value["Light Intensity"] * controlValues.value["CO2 Level"] * controlValues.value["Water Level"]) / 125000;

  // Limit plant height to half of the canvas height
  const newHeight = plantHeight.value + photosynthesisRate * 0.1;
  plantHeight.value = Math.min(newHeight, maxPlantHeight);
  
  oxygenProduced.value += photosynthesisRate * 0.5;
  glucoseProduced.value += photosynthesisRate * 0.3;

  drawTree(); // Now drawing the enhanced tree structure
  animationId = requestAnimationFrame(updateSimulation);
};

const drawTree = () => {
  const canvas = canvasRef.value;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw soil
  const soilHeight = 30;
  ctx.fillStyle = '#8B4513'; // Darker brown for soil
  ctx.fillRect(0, canvas.height - soilHeight, canvas.width, soilHeight);

  // Draw trunk with tapering effect
  const trunkBaseWidth = 40; // Wider base
  const trunkTipWidth = 10; // Tapered tip
  const trunkBaseY = canvas.height - soilHeight;

  // Draw trunk
  ctx.fillStyle = '#6B4226'; // Rich brown for trunk
  ctx.beginPath();
  ctx.moveTo(canvas.width / 2 - trunkBaseWidth / 2, trunkBaseY);
  ctx.lineTo(canvas.width / 2 + trunkBaseWidth / 2, trunkBaseY);
  ctx.lineTo(canvas.width / 2 + trunkTipWidth / 2, trunkBaseY - plantHeight.value);
  ctx.lineTo(canvas.width / 2 - trunkTipWidth / 2, trunkBaseY - plantHeight.value);
  ctx.closePath();
  ctx.fill();

  // Draw branches as the tree grows
  const branchInterval = 50; // Distance between branches
  const branchLength = 40;

  for (let i = 1; i < plantHeight.value / branchInterval; i++) {
    const branchY = trunkBaseY - i * branchInterval;
    const branchDirection = i % 2 === 0 ? 1 : -1; // Alternate branches left and right

    // Draw branch
    ctx.strokeStyle = '#6B4226'; // Same brown color for branches
    ctx.lineWidth = 10;
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2, branchY);
    ctx.lineTo(canvas.width / 2 + branchDirection * branchLength, branchY - 20); // Angled branch
    ctx.stroke();

    // Draw leaves on branches
    drawLeaves(ctx, canvas.width / 2 + branchDirection * branchLength, branchY - 20, 30); // Larger leaves
  }

  // Draw leaves at the top of the tree
  drawLeaves(ctx, canvas.width / 2, trunkBaseY - plantHeight.value, 25); // Smaller leaf size at the top

  // Draw sun
  ctx.fillStyle = `rgba(255, 255, 0, ${controlValues.value["Light Intensity"] / 100})`;
  ctx.beginPath();
  ctx.arc(50, 50, 30, 0, 2 * Math.PI);
  ctx.fill();

  // Visualize CO2
  ctx.fillStyle = `rgba(169, 169, 169, ${controlValues.value["CO2 Level"] / 200})`;
  for (let i = 0; i < 5; i++) {
    ctx.beginPath();
    ctx.arc(Math.random() * canvas.width, Math.random() * canvas.height, 5, 0, 2 * Math.PI);
    ctx.fill();
  }

  // Visualize water
  ctx.fillStyle = `rgba(0, 191, 255, ${controlValues.value["Water Level"] / 200})`;
  ctx.fillRect(0, canvas.height - soilHeight - 5, canvas.width, 5);
};

// Helper function to draw leaves
const drawLeaves = (ctx, x, y, size) => {
  ctx.fillStyle = '#32CD32'; // Bright green for leaves
  ctx.beginPath();
  ctx.ellipse(x - size / 2, y, size, size / 2, Math.PI / 4, 0, 2 * Math.PI);
  ctx.fill();
  ctx.beginPath();
  ctx.ellipse(x + size / 2, y, size, size / 2, -Math.PI / 4, 0, 2 * Math.PI);
  ctx.fill();
};

const resetSimulation = () => {
  oxygenProduced.value = 0;
  glucoseProduced.value = 0;
  plantHeight.value = 5; // Reset the plant to a very small seedling
};

watch([controlValues.value["Light Intensity"], controlValues.value["CO2 Level"], controlValues.value["Water Level"]], resetSimulation);

onMounted(() => {
  updateSimulation();
});

onUnmounted(() => {
  if (animationId) {
    cancelAnimationFrame(animationId);
  }
});
</script>

<style scoped>
html, body {
  height: 100%; /* Set full height for html and body */
  margin: 0; /* Remove default margin */
}

.photosynthesis-simulation {
  font-family: 'Arial', sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(to bottom right, #e0f7fa, #ffffff);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative; /* For better stacking context */
  z-index: 1; /* Ensure it sits above background */
}

.simulation-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

canvas {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 40px;
}

.control-group {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.results {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.explanation {
  background-color: #e6f7ff;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}

h1, h2 {
  color: #2c3e50;
}

input[type="range"] {
  width: 100%;
  margin-top: 10px;
}

/* Fullscreen background */
.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom right, #b3e5fc, #ffffff); /* Adjust as needed */
  z-index: 0; /* Ensure it is behind the content */
}
</style>
