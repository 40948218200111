<!-- RefractiveIndexSimulation.vue -->
<script setup>
import { ref } from 'vue';
import ExplanationComponent from '../../components/guest/explantions/ReFraction.vue';
import ExperimentComponent from '../../components/guest/experiments/ReFraction.vue';

const mode = ref('explanation'); // 'explanation' or 'experiment'

const toggleMode = () => {
  mode.value = mode.value === 'explanation' ? 'experiment' : 'explanation';
};
</script>

<template>
  <div class="simulation-container">
    <ExplanationComponent v-if="mode === 'explanation'" @complete="toggleMode" />
    <ExperimentComponent v-else />
    <button @click="toggleMode" class="toggle-button">
      {{ mode === 'explanation' ? 'Skip to Experiment' : 'Back to Explanation' }}
    </button>
  </div>
</template>

<style scoped>
.simulation-container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #45a049;
}
</style>