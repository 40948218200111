<!-- ExplanationComponent.vue -->
<script setup>
import { ref, onMounted, defineEmits } from 'vue';

const emit = defineEmits(['complete']);

const currentStep = ref(0);
const currentText = ref('');
const isTyping = ref(false);

const explanationSteps = [
  "Welcome to the Refractive Index Simulation!",
  "Light travels at different speeds through different mediums.",
  "This causes light to bend, or refract, when it passes from one medium to another.",
  "The angle at which light bends depends on the refractive indices of the mediums.",
  "Let's explore how this works with our simulation!"
];

const typeWriter = (text, index = 0) => {
  if (index < text.length) {
    currentText.value += text[index];
    setTimeout(() => typeWriter(text, index + 1), 50);
  } else {
    isTyping.value = false;
  }
};

const nextStep = () => {
  if (currentStep.value < explanationSteps.length - 1) {
    currentStep.value++;
    currentText.value = '';
    isTyping.value = true;
    typeWriter(explanationSteps[currentStep.value]);
    speak(explanationSteps[currentStep.value]);
  } else {
    emit('complete');
  }
};

const speak = (text) => {
  if ('speechSynthesis' in window) {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  }
};

onMounted(() => {
  isTyping.value = true;
  typeWriter(explanationSteps[0]);
  speak(explanationSteps[0]);
});
</script>

<template>
  <div class="explanation">
    <p>{{ currentText }}</p>
    <button @click="nextStep" :disabled="isTyping">Next</button>
  </div>
</template>

<style scoped>
.explanation {
  padding: 20px;
  font-size: 18px;
  line-height: 1.5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

p {
  margin-bottom: 20px;
  max-width: 600px;
  text-align: center;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover:not(:disabled) {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>