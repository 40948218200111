<template>
    <div class="container-fluid bg-light py-4">
      <div class="card-header bg-success text-white py-3 mb-4" style="border-radius:15px">
        <h1 class="text-center">🖐️ Hand Gesture Detector 👋</h1>
      </div>
      <div class="row">
        <!-- Left column: Video feed -->
        <div class="col-lg-6">
          <div class="video-container mb-3">
            <video ref="videoRef" autoplay playsinline class="webcam-video"></video>
            <canvas ref="canvasRef" class="webcam-canvas"></canvas>
          </div>
          <div class="text-center mb-3">
            <button class="btn btn-lg bg-success text-white" @click="toggleDetection">
              {{ isDetecting ? '🛑 Stop Detection' : '🚀 Start Detection' }}
            </button>
          </div>
        </div>
  
        <!-- Right column: Results and explanations -->
        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-header bg-success text-white">
              <h2 class="h4 mb-0">✋ Detected Hands</h2>
            </div>
            <div class="card-body">
              <p>Number of hands detected: {{ detectedHands }}</p>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-header bg-success text-white">
              <h2 class="h4 mb-0">🧠 How It Works</h2>
            </div>
            <div class="card-body">
              <p>Ever wonder how VR hand controllers work? It's all about hand landmarks! Here's a quick breakdown:</p>
              <ul>
                <li>Our AI brain looks for specific points on your hand, like knuckles, fingertips, and the wrist.</li>
                <li>It then uses these points to create a 3D model of your hand in real-time.</li>
                <li>We can use the relationships between these points to recognize gestures or track hand movements.</li>
                <li>This technology is used in various applications, from sign language recognition to touchless interfaces!</li>
              </ul>
              <p>Cool, right? It's like your hand is a constellation, and our AI is connecting the stars!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { HandLandmarker, FilesetResolver } from '@mediapipe/tasks-vision';
  
  // Hand connection pairs
  const HAND_CONNECTIONS = [
    [0, 1], [1, 2], [2, 3], [3, 4], // Thumb
    [0, 5], [5, 6], [6, 7], [7, 8], // Index finger
    [5, 9], [9, 10], [10, 11], [11, 12], // Middle finger
    [9, 13], [13, 14], [14, 15], [15, 16], // Ring finger
    [13, 17], [17, 18], [18, 19], [19, 20], // Pinky finger
    [0, 17] // Wrist to pinky base
  ];
  
  export default {
    name: 'HandGestureDetector',
    setup() {
      const videoRef = ref(null);
      const canvasRef = ref(null);
      const isDetecting = ref(false);
      const detectedHands = ref(0);
      let handLandmarker = null;
  
      const startWebcam = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          if (videoRef.value) {
            videoRef.value.srcObject = stream;
            videoRef.value.addEventListener('loadeddata', () => {
              canvasRef.value.width = videoRef.value.videoWidth;
              canvasRef.value.height = videoRef.value.videoHeight;
            });
          }
        } catch (error) {
          console.error('Error accessing the webcam:', error);
        }
      };
  
      const stopWebcam = () => {
        if (videoRef.value && videoRef.value.srcObject) {
          const tracks = videoRef.value.srcObject.getTracks();
          tracks.forEach(track => track.stop());
          videoRef.value.srcObject = null;
        }
      };
  
      const initializeHandLandmarker = async () => {
        try {
          const filesetResolver = await FilesetResolver.forVisionTasks(
            "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
          );
          handLandmarker = await HandLandmarker.createFromOptions(filesetResolver, {
            baseOptions: {
              modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task`,
              delegate: "GPU"
            },
            numHands: 4, // Increase this to detect more hands
            runningMode: "VIDEO"
          });
        } catch (error) {
          console.error('Error initializing HandLandmarker:', error);
        }
      };
  
      const detectHands = async () => {
        if (!handLandmarker) return;
  
        if (videoRef.value.videoWidth > 0) {
          const detections = handLandmarker.detectForVideo(videoRef.value, performance.now());
  
          if (detections && detections.landmarks && detections.landmarks.length > 0) {
            drawResults(detections.landmarks);
            detectedHands.value = detections.landmarks.length;
          } else {
            detectedHands.value = 0;
            clearCanvas();
          }
        }
  
        if (isDetecting.value) {
          requestAnimationFrame(detectHands);
        }
      };
  
      const drawResults = (landmarksArray) => {
        const ctx = canvasRef.value.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
  
        for (const landmarks of landmarksArray) {
          drawConnectors(ctx, landmarks, HAND_CONNECTIONS, { color: '#00FF00', lineWidth: 5 });
          drawLandmarks(ctx, landmarks, { color: '#FF0000', lineWidth: 2 });
        }
      };
  
      const drawConnectors = (ctx, landmarks, connections, style) => {
        for (const connection of connections) {
          const from = landmarks[connection[0]];
          const to = landmarks[connection[1]];
          if (from && to) {
            ctx.beginPath();
            ctx.moveTo(from.x * canvasRef.value.width, from.y * canvasRef.value.height);
            ctx.lineTo(to.x * canvasRef.value.width, to.y * canvasRef.value.height);
            ctx.strokeStyle = style.color;
            ctx.lineWidth = style.lineWidth;
            ctx.stroke();
          }
        }
      };
  
      const drawLandmarks = (ctx, landmarks, style) => {
        for (const landmark of landmarks) {
          ctx.beginPath();
          ctx.arc(landmark.x * canvasRef.value.width, landmark.y * canvasRef.value.height, 5, 0, 2 * Math.PI);
          ctx.fillStyle = style.color;
          ctx.fill();
        }
      };
  
      const clearCanvas = () => {
        const ctx = canvasRef.value.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
      };
  
      const toggleDetection = async () => {
        isDetecting.value = !isDetecting.value;
        if (isDetecting.value) {
          if (!handLandmarker) {
            await initializeHandLandmarker();
          }
          detectHands();
        } else {
          clearCanvas();
          detectedHands.value = 0;
        }
      };
  
      onMounted(async () => {
        await startWebcam();
      });
  
      onUnmounted(() => {
        stopWebcam();
      });
  
      return {
        videoRef,
        canvasRef,
        isDetecting,
        toggleDetection,
        detectedHands
      };
    }
  };
  </script>
  
  <style scoped>
  .video-container {
    position: relative;
    width: 100%;
    max-width: 640px;
    aspect-ratio: 4 / 3;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .webcam-video, .webcam-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .webcam-video {
    background-color: #000;
  }
  
  .webcam-canvas {
    z-index: 10;
  }
  
  .btn-lg {
    font-size: 1.2rem;
    padding: 0.75rem 1.5rem;
  }
  
  .card-header {
    border-radius: 15px 15px 0 0;
  }
  
  .card-body {
    font-family: 'Arial', sans-serif;
  }
  </style>
  